import React, { SVGProps } from 'react';

const DataAnalyticIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <rect width="13" height="13" x=".5" y=".5" rx="1" transform="rotate(180 7 7)"></rect>
        <path d="M3 3h2M3 5.5h4.5m4 0l-3 5l-3.5-2l-2 3"></path>
      </g>
    </svg>
  );
};

const MemoDataAnalyticIcon = React.memo(DataAnalyticIcon);
export default MemoDataAnalyticIcon;
