import AndroidIcon from '@/shared/components/icons/professions/android-icon';
import BackendIcon from '@/shared/components/icons/professions/backend-icon';
import CPlusPlusIcon from '@/shared/components/icons/professions/c-plus-plus-icon';
import DataAnalyticIcon from '@/shared/components/icons/professions/data-analytic-icon';
import { DesignIcon } from '@/shared/components/icons/professions/design-icon';
import { DevIcon } from '@/shared/components/icons/professions/dev-icon';
import IosIcon from '@/shared/components/icons/professions/ios-icon';
import UxUiIcon from '@/shared/components/icons/professions/ux-ui-icon';

interface Section {
  title?: string;
  content: Array<string> | string;
}

export interface CaseInfo {
  sections: Array<Section>;
  review: string;
}

export interface StudentCase {
  Icon?: any;
  name: string;
  description: string;
  href: string;
  cta: string;
  className: string;
  info?: CaseInfo;
}

export const STUDENTS_CASES: Array<StudentCase> = [
  {
    Icon: BackendIcon,
    name: 'Артемий, Backend developer',
    description: 'Оффер в Дубай на 8500$ в месяц за 4 месяца занятий.',
    href: '/cases/backend-dev-offer-uae',
    cta: 'Читать кейс',

    className: 'lg:row-start-1 lg:row-end-4 lg:col-start-2 lg:col-end-3',

    info: {
      sections: [
        {
          content: 'На момент начала занятий уровень английского был начинающий В1',
        },

        {
          title: 'Цели',
          content: [
            'начать еще увереннее говорить на английском',
            'вспомнить необходимую грамматику',
            'потренироваться в ответах на вопросы с собеседований, чтобы устроиться в иностранную компанию',
          ],
        },

        {
          content: 'Занятия проходили по программе IT английского и подготовки к интервью.',
        },

        {
          title: 'Что было сделано за 4 месяца',
          content: [
            'повторили необходимую грамматику и лексику',
            'попрактиковали mock и competency-based interviews по интересующим вакансиям',
            'много креативной домашки: эссе, видео',
            'улучшили LinkedIn и CV',
          ],
        },

        {
          content:
            'В результате удалось достичь уверенного В1 в английском, начать бегло разговаривать и, конечно же, успешно пройти собеседование на английском в иностранную компанию.',
        },
      ],

      review: '/images/reviews/artemiy-be.webp',
    },
  },

  {
    Icon: DataAnalyticIcon,
    name: 'Кристина, Data analyst',
    description: 'Оффер в США через 5 занятий. Мы рассмотрели вопросы на собеседованиях и профессиональную лексику.',
    href: '/cases/data-analyst-offer-usa',
    cta: 'Читать кейс',

    className: 'lg:row-start-1 lg:row-end-3 lg:col-start-1 lg:col-end-2',

    info: {
      sections: [
        {
          content:
            'На момент начала занятий у Кристины уже был довольно высокий уровень английского, она свободно говорила.',
        },
        {
          title: 'Цель',
          content: [
            'Рассмотреть возможные вопросы собеседований',
            'Вспомнить полезные фразы для коммуникации',
            'Расширить профессиональную лексику, чтобы звучать профессионально и преодолеть языковой барьер перед собеседованием.',
          ],
        },
        {
          title: 'Что сделали за 5 занятий',
          content: [
            'Разобрали рассказ о себе по структуре, с полезными фразами, примерами для сферы, ключевыми глаголами для аналитиков данных.',
            'Практиковали ответы на вопросы о предыдущем опыте работы, роли в компаниях, технических навыках, достижениях, карьерных планах, заинтересованности в вакансии.',
            'Вспомнили функциональные фразы для разных ситуаций на интервью (как начать, переспросить, выиграть время для ответа, звучать дружелюбно), фразы-связки.',
          ],
        },
        {
          content:
            'Кристина избавилась от своего «синдрома самозванца» и смогла пройти несколько этапов интервью. Несмотря на стресс, была готова отвечать на ожидаемые вопросы, так как мы практиковались заранее. В результате Кристина успешно прошла собеседование в американскую компанию и планирует там работать.',
        },
      ],

      review: '/images/reviews/kristina-analyst.webp',
    },
  },

  {
    Icon: CPlusPlusIcon,
    name: 'Салават, Senior software engineer, C++',
    description: 'Подготовка к собеседованию за 1,5 месяца.',
    href: '/cases/senior-dev-interview-prep',
    cta: 'Читать кейс',

    className: 'lg:row-start-3 lg:row-end-4 lg:col-start-1 lg:col-end-2',

    info: {
      sections: [
        {
          content:
            'Салават пришел к нам с уровнем языка В1 и с целью подготовиться к собеседованию, чувствовать себя увереннее при общении на английском.',
        },
        {
          title: 'Цели',
          content: ['Подготовиться к собеседованию', 'Чувствовать себя увереннее при общении на английском'],
        },
        {
          title: 'Что было сделано за полтора месяца',
          content: [
            'Вспомнили и выучили основную грамматику, необходимую для успешной рабочей коммуникации',
            'Много работали над разговорной практикой',
            'Обогатили словарный запас необходимой лексикой для собеседований и работы в IT, которую сразу же отрабатывали в речи',
          ],
        },
        {
          content: 'По итогам занятий удалось добиться всех поставленных целей!',
        },
      ],

      review: '/images/reviews/salavat-c-plus.webp',
    },
  },

  {
    Icon: IosIcon,
    name: 'Ксения, iOS developer',
    description: 'Оффер в Литовскую компанию через 2,5 месяца занятий.',
    href: '/cases/ios-dev-offer-europe',
    cta: 'Читать кейс',

    className: 'lg:row-start-1 lg:row-end-2 lg:col-start-3 lg:col-end-3',

    info: {
      sections: [
        {
          content:
            'На момент начала занятий Ксения находилась в активном поиске работы в зарубежную компанию, у нее не было написано резюме, уровень английского В1.',
        },
        {
          title: 'Цель',
          content: ['Подготовиться к собеседованию на английском'],
        },
        {
          title: 'Анализ потребностей',
          content: [
            'Перед началом занятий мы провели подробный need analysis',
            'Обозначили 2-2,5 месяца на подготовку',
            'Разработали подробный roadmap, который включал темы, на которые было необходимо уделить особое внимание.',
          ],
        },
        {
          title: 'Что было сделано за 2,5 месяца',
          content: [
            'Разобрали способы и фразы, которыми можно говорить о своей работе и основных обязанностях и опыте',
            'Написали elevator pitch',
            'Научились использовать метод STAR при ответе на вопросы интервьюера',
            'Рассмотрели основные hard и soft skills вопросы',
            'Изучили основы этикета бизнес звонков',
            'Изучили лексику, связанную с техническими специальностями',
            'Поработали над произношением с помощью скороговорок',
            'Научились структурированно высказывать свое мнение и давать аргументированные ответы',
            'Разобрали фразы, которыми можно поддерживать small talk',
            'Разобрали frameworks for software development (Agile, Kanban, Scrum)',
            'Повторили необходимую грамматику (времена, модальные глаголы)',
          ],
        },
        {
          content:
            'По итогам занятий Ксения получила оффер на должность iOS developer от европейской компании и сейчас находится в процессе переезда и адаптации на новом месте.',
        },
      ],

      review: '/images/reviews/kseniya-ios.webp',
    },
  },

  {
    Icon: AndroidIcon,
    name: 'Александр, Android developer',
    description:
      'Получение оффера спустя 3 месяца занятий. Отточили скорость речи, вокабуляр, самопрезентацию. Улучшили LinkedIn и CV.',
    href: '/cases/android-dev-offer-after-3-months',
    cta: 'Читать кейс',

    className: 'lg:row-start-2 lg:row-end-4 lg:col-start-3 lg:col-end-3',

    info: {
      sections: [
        {
          content:
            'Александр пришел на занятия с уровнем английского А2+. Основная проблема заключалась в скорости построения предложений и отсутствии нужного вокабуляра. Из-за этого в речи часто возникали паузы, и Александр давал короткие и односложные ответы.',
        },
        {
          title: 'Цель',
          content: ['Подтянуть уровень языка для прохождения собеседований на английском.'],
        },
        {
          title: 'Что было сделано за 3 месяца',
          content: [
            'Разобрали основные темы, чтобы можно было описывать характеристики приложений, рассказывать о своем опыте, рабочих процессах и трудностях, возникающих в работе',
            'Научились предлагать и аргументировать свои идеи',
            'Отточили самопрезентацию, разобрали основные вопросы, встречающиеся на собеседованиях, учились выгодно презентовать свой опыт.',
            'Улучшили LinkedIn и CV.',
            'Выучили основную грамматику, необходимую для успешной коммуникации на английском.',
          ],
        },
        {
          title: 'Материалы',
          content: [
            'В работе использовали учебники по деловому английскому',
            'Материалы с Indeed и видео с YouTube',
            'Онлайн словарь с флеш карточками',
          ],
        },
        {
          content:
            'По итогам занятий, кроме получения оффера, у Александра произошел существенный прогресс: он стал строить более сложные и распространенные предложения, научился перефразировать предложения, чтобы понятно доносить свои мысли.',
        },
      ],

      review: '/images/reviews/aleksandr-android.webp',
    },
  },

  {
    Icon: UxUiIcon,
    name: 'Мария, UX Researcher',
    description: 'Оффер в международном стартапе спустя 2 месяца подготовки.',
    href: '/cases/international-startup-offer',
    cta: 'Читать кейс',

    className: 'lg:row-start-4 lg:row-end-7 lg:col-start-1 lg:col-end-2',

    info: {
      sections: [
        {
          content:
            'Мария пришла к нам, когда уже жила за границей и имела значительный опыт работы на фрилансе. Однако у нее появилась цель устроиться в международную компанию.',
        },
        {
          title: 'Цель',
          content: ['За 2-3 месяца выйти на собеседования с рекрутерами компаний.'],
        },
        {
          title: 'Фокус занятий',
          content: [
            'Улучшении практических навыков, так как у Марии уже имелась база английского.',
            'Занятия проводились в паре с другой ученицей, у которой схожий опыт работы и стэк, чтобы делать обсуждение более разнообразным и задавать вопросы коллеге.',
          ],
        },
        {
          title: 'Что было сделано',
          content: [
            'Составили резюме, сопроводительное письмо и самопрезентацию.',
            'Прошли основные блоки вопросов об опыте работы, обсудили сильные и слабые стороны, разработали кейс и составили портфолио.',
          ],
        },
        {
          title: 'Дополнительная работа',
          content: [
            'Мария самостоятельно выполняла значительную работу. Она писала посты в LinkedIn на различные темы, связанные с работой, чтобы улучшить свои навыки коммуникации на английском.',
          ],
        },
        {
          title: 'Сложности и успехи',
          content:
            'В ходе процесса у Маши возникли сложности - первое интервью не было успешным. Однако мы вместе с ней извлекли уроки и внесли коррективы в подготовку. Сейчас Мария успешно прошла отбор в международный стартап, но английский не бросает, а продолжает улучшать свои навыки.',
        },
      ],

      review: '/images/reviews/mariya-ux.webp',
    },
  },

  {
    Icon: DesignIcon,
    name: 'Дмитрий, Senior Product Designer',
    description: 'Оффер в американский стартап спустя 2,5 месяца занятий.',
    href: '/cases/usa-startup-offer',
    cta: 'Читать кейс',

    className: 'lg:row-start-4 lg:row-end-5 lg:col-start-2 lg:col-end-2',

    info: {
      sections: [
        {
          content:
            'Дмитрий пришел к нам с хорошим fluency речи, так как много общается в путешествиях на английском и изучает много материала на профессиональные темы в статьях и на YouTube.',
        },
        {
          title: 'Цель',
          content: [
            'Звучать более профессионально, используя лексику из product дизайна и уверенно представлять свой опыт, чтобы получить Senior позицию в зарубежной компании.',
          ],
        },
        {
          title: 'Что было сделано за 2,5 месяца',
          content: [
            'Разобрали стратегию и полезные фразы для представления себя, своих сильных и слабых сторон, навыков личных и технических для screening interview на основе примеров.',
            'Обсудили case study разработки реального приложения и сравнили с собственным опытом.',
            'Расширили лексику из бизнес английского для обсуждения своего опыта и применения терминов из UI/UX дизайна в речи.',
          ],
        },
        {
          title: 'Результаты',
          content: [
            'В результате Дмитрий прошел несколько этапов интервью на английском - тестовое задание в формате user interview и с product ownerом (нейтивом, американцем).',
            'Подготовка ответов на вопросы интервью заранее дала Диме большую уверенность, так как это смотивировало проанализировать свой опыт в аспекте своих достижений, преимуществ, интересных кейсов.',
          ],
        },
        {
          content:
            'Но теперь перед Димой новый челлендж – проводить user interview на английском, что для него новый опыт, поэтому он продолжает заниматься регулярно, чтобы делать свою работу так же профессионально и круто, как он это делает на русском.',
        },
      ],
      review: '/images/reviews/dmitriy-prod-designer.webp',
    },
  },

  {
    Icon: DevIcon,
    name: 'Павел, Software Development',
    description: 'Оффер в Германии через 3 месяца занятий.',
    href: '/cases/germany-job-offer',
    cta: 'Читать кейс',

    className: 'lg:row-start-5 lg:row-end-7 lg:col-start-2 lg:col-end-2',

    info: {
      sections: [
        {
          content:
            'Целью Павла было подтянуть английский и попрактиковать вопросы с собеседований перед ответственным собеседованием на английском.',
        },
        {
          title: 'Что сделали за 3 месяца',
          content: [
            'Выучили много полезной лексики для прохождения интервью и пополнили технический вокабуляр (обсуждали статьи по специальности Павла и технические вопросы).',
            'Побороли языковой барьер при ответах на вопросы с собеседований (студент не переживал при рассказе о себе, с уверенностью начал говорить о достижениях).',
            'Повторили базовую грамматику, необходимую для прохождения собеседований.',
          ],
        },
        {
          title: 'Результаты',
          content: [
            'В результате Павел начал увереннее себя чувствовать в английском и нашел работу в немецкой компании.',
          ],
        },
      ],
      review: '/images/reviews/pavel-dev.webp',
    },
  },

  {
    Icon: DevIcon,
    name: 'Дмитрий, Software Developer',
    description: 'Подготовка к собеседованию на английском за 3 месяца занятий.',
    href: '/cases/international-company-interview-preparation',
    cta: 'Читать кейс',

    className: 'lg:row-start-4 lg:row-end-6 lg:col-start-3 lg:col-end-3',

    info: {
      sections: [
        {
          content:
            'Дмитрий пришел к нам с целью подготовиться к собеседованию и устроиться в международную компанию. Перед началом занятий Дарья составила план работы и расписала пошаговые действия для достижения цели в короткие сроки.',
        },
        {
          title: 'Что было сделано за 3 месяца',
          content: [
            'Повторили базовую грамматику.',
            'Разобрали софтовые вопросы.',
            'Составили самопрезентацию.',
            'Отредактировали профиль на LinkedIn.',
            'Написали CV.',
            'Изучили и отработали на практике лексику для рабочих созвонов.',
          ],
        },
        {
          title: 'Результаты',
          content: [
            'В результате у Дмитрия сократилось количество ошибок в речи, она стала более уверенной, а также улучшилось произношение.',
            'А главное - Дмитрий прошел собеседование!',
          ],
        },
      ],

      review: '/images/reviews/dmitriy-dev.webp',
    },
  },

  {
    Icon: DevIcon,
    name: 'Гузель, Software Developer',
    description: 'С А2 до В1 за 6 месяцев + получение офера в международную компанию.',
    href: '/cases/international-company-offer',
    cta: 'Читать кейс',

    className: 'lg:row-start-6 lg:row-end-7 lg:col-start-3 lg:col-end-3',

    info: {
      sections: [
        {
          content:
            'Гузель испытывала страх говорить на английском, избегала англоговорящие ресурсы, имела маленький словарный запас, сложности с составлением предложений и выбором подходящих слов и конструкций, путалась в употреблении основных времен, артиклей и предлогов, имела медленную скорость речи.',
        },
        {
          title: 'Цель',
          content: [
            'Повысить уровень языка.',
            'Говорить бегло и уверенно.',
            'Подготовиться к собеседованию.',
            'Научиться свободно общаться с командой на регулярной основе (устно и письменно).',
          ],
        },
        {
          title: 'Темы, которые обсудили во время занятий',
          content: [
            'Projects, tasks, deadlines, soft and hard skills, mindset, productivity, procrastination, trending tech, corporate culture, toxic peers, misunderstandings at work, elevator pitch, cover letter, effective presentations, job interview Q&A, S.T.A.R. method, recruitment process, e-mail communication, networking.',
          ],
        },
        {
          title: 'Результаты',
          content: [
            'Сейчас Гузель уверенно и бегло говорит на английском, правильно использует артикли, предлоги, времена и структурирует предложения.',
            'Использует лексику уровня В1, меньше тратит времени на выбор слов.',
            'Знает как грамотно и понятно отвечать на вопросы собеседования и общаться в команде.',
          ],
        },
      ],
      review: '/images/reviews/guzel-dev.webp',
    },
  },
];
